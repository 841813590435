<script setup>
import { GA_ACCOUNTS } from '@/config/analytics';
import { commonValues } from '@/config/common.js';
import { useCurrencyStore } from '@/stores/currency';
import { WdsButtonGhost } from '@wds/components';

const runtimeConfig = useRuntimeConfig();
const { $tracking } = useNuxtApp();
const {
  t,
  locale,
} = useI18n();
const { useUrl } = useUrls();
const currencyStore = useCurrencyStore();
const testingStore = useTestingStore();
const languageStore = useLanguageStore();
const envData = runtimeConfig?.public;
const { isTrackingAllowed } = checkGdpr();
const { isExtraSmallScreen } = useMq();

const isGoogleTagManagerEnabled = testingStore?.hasEnabledFeature('web_googleTagManager');
const isHotjarEnabled = testingStore.hasEnabledFeature('web_hotjar');

const COOKIESPOLICYURL = useUrl.getCookiePolicyUrl();
const SECURITYPRIVACYURL = useUrl.getSecurityPrivacyUrl();

const actualLanguage = locale?.value;

// @TODO: For some weird reason, the trustArc banner is being rendered twice on DOM, but script is only called once
const trustArcScript = [
  {
    async: true,
    crossorigin: 'anonymous',
    fetchpriority: 'high',
    src:
      'https://consent.trustarc.com/notice?domain=hostelworld.com'
      + '&c=teconsent'
      + '&gtm=1'
      + '&js=nj'
      + '&noticeType=bb'
      + `&language=${actualLanguage}`
      + '&language=true'
      + '&text=true'
      + `&cookieLink=${encodeURIComponent(`${COOKIESPOLICYURL}`)}&privacypolicylink=${encodeURIComponent(
        `${SECURITYPRIVACYURL}`,
      )}`,
  },
];

const googleHpa = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtag',
      defer: true,
      crossorigin: 'anonymous',
      src: `https://www.googletagmanager.com/gtag/js?id=${GA_ACCOUNTS[0].ID}`,
    },
  ]
  : [];

const gtm = isGoogleTagManagerEnabled
  ? [
    {
      key: 'gtm',
      defer: true,
      crossorigin: 'anonymous',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f?.parentNode?.insertBefore(j,f);
            })(window,document,'script','dataLayer','${envData.GTM_ID}');`,
    },
  ]
  : [];

const hotjar
  = isTrackingAllowed && isHotjarEnabled
    ? [
      {
        key: 'hotjar',
        async: true,
        defer: true,
        src: '//u.hwstatic.com/hw/hotjar/hotjar.js',
      },
    ]
    : [];

const buttonMerchantScirpt = toValue(isExtraSmallScreen)
  ? [
    {
      key: 'merchant-script',
      crossorigin: 'anonymous',
      children: `
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          window.ButtonWebConfig = {
            applicationId: 'app-2e632f0c7172fd31'
          };

          (function (u, s, e, b, t, n) {
            u['__bttnio'] = b; u[b] = u[b] || function () { (u[b].q = u[b].q || []).push(arguments) }; t = s.createElement(e); n = s.getElementsByTagName(e)[0]; t.async = 1; t.src = 'https://web.btncdn.com/v1/button.js'; n.parentNode.insertBefore(t, n);
          })(window, document, 'script', 'bttnio');
        }
      `,
    },
  ]
  : [];

const headerScripts = [...trustArcScript, ...googleHpa, ...gtm, ...hotjar, ...buttonMerchantScirpt];

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  script: headerScripts,
}));

const seoMetaData = {
  title: `${commonValues?.HOSTELWORLD} - ${t('t_ERRORSERVERERROR500')}`,
  ogTitle: `${commonValues?.HOSTELWORLD} - ${t('t_ERRORSERVERERROR500')}`,
  description: `${t('t_ERROROOPS')} ${t('t_ERRORPOWERNAP')} ${t('t_ERRORSERVERERROR500')}`,
  ogDescription: `${t('t_ERROROOPS')} ${t('t_ERRORPOWERNAP')} ${t('t_ERRORSERVERERROR500')}`,
  canonicalUrl: commonValues?.HOSTELWORLDURL,
  ogImage: commonValues?.OGIMAGE,
  keywords: '',
  copy: '',
  heading: '',
  h1: '',
  h2: '',
};

useSeoMeta({
  title: () => seoMetaData?.title,
  ogTitle: () => seoMetaData?.ogTitle,
  description: () => seoMetaData?.description,
  ogDescription: () => seoMetaData?.ogDescription,
  ogUrl: () => seoMetaData?.canonicalUrl,
  ogImage: () => seoMetaData?.ogImage,
  ogImageAlt: () => seoMetaData?.ogDescription,
  ogType: () => 'website',
  keywords: () => seoMetaData?.keywords,
  robots: () => 'noindex, nofollow',
  twitterCard: () => 'summary_large_image',
  twitterTitle: () => seoMetaData?.ogTitle,
  twitterDescription: () => seoMetaData?.ogDescription,
  twitterImage: () => seoMetaData?.ogImage,
  twitterImageAlt: () => seoMetaData?.ogDescription,
});

// ### TRACKING
const trackingInfo = {
  gtm: { gtmPageName: 'error500Page' },
  segment: {
    event_name: '500',
    page_type: '500',
  },
};
$tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);

// ### METHODS
function refresh() {
  setTimeout(() => {
    clearError();
    location.reload();
  }, 100);
}

onUnmounted(() => {
  clearError();
});
</script>

<template>
  <div class="container">
    <div class="wrapper">
      <div class="inner-wrapper">
        <CommonHwNavBar />
      </div>
    </div>

    <CommonHwLanguagePicker v-show="languageStore?.isLanguagePickerOpen" />

    <ClientOnly>
      <LazyCommonHwCurrencyPicker v-if="currencyStore?.isCurrencyPickerOpen" />
    </ClientOnly>

    <main class="error-500-page">
      <div class="wrapper">
        <div class="inner-wrapper">
          <div class="content">
            <div class="inner-content">
              <div class="error-img">
                <img
                  src="@/public/flamingo.svg"
                  :alt="$t('t_ERRORFLAMINGO')"
                  :title="$t('t_ERRORFLAMINGO')"
                />
              </div>

              <div class="error-text">
                <h1>{{ $t('t_ERROROOPS') }}</h1>
                <h2>{{ $t('t_ERRORPOWERNAP') }}</h2>
                <h3>{{ $t('t_ERRORSERVERERROR500') }}</h3>

                <WdsButtonGhost
                  type="button"
                  :text="$t('t_REFRESH')"
                  icon-start="free-cancelation"
                  rel="noopener"
                  @click="refresh"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <CommonHwFooter />

    <ClientOnly>
      <LazyCommonHwCookieBar />
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
nav {
  margin-top: $wds-spacing-s;
}

.error-500-page {
  background-color: $wds-color-teal-light;
  padding: 0 0 wds-rem(70px);
  position: relative;
  width: 100%;
  clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);

  .inner-wrapper {
    margin: 0 $wds-spacing-xl;
  }

  .content {
    padding: $wds-spacing-m;
    display: flex;
    flex-direction: row;

    @include tablet {
      padding-bottom: wds-rem(62px);
    }

    @include desktop {
      margin-top: wds-rem(35px);
    }

    .inner-content {
      display: flex;
      flex-direction: column;

      @include tablet {
        flex-direction: row-reverse;
      }

      @include tablet-large {
        max-width: wds-rem(960px);
        margin: 0 auto;
      }
    }
  }

  .error-img {
    width: 100%;

    @include tablet {
      margin-top: wds-rem(30px);
      margin-bottom: wds-rem(30px);
      width: 80%;
    }
  }

  .error-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    h1 {
      margin-bottom: $wds-spacing-s;

      @include title-1-bld;
    }

    h2 {
      margin-bottom: $wds-spacing-s;

      @include title-3-bld;
    }

    h3 {
      margin-bottom: $wds-spacing-s;

      @include title-6-reg;
    }

    @include tablet {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 15%;
      margin-bottom: 30px;

      h3 {
        margin-bottom: $wds-spacing-xl;
      }
    }

    @include desktop {
      h2 {
        @include title-2-bld;
      }
    }
  }
}

footer {
  margin-top: wds-rem(-70px);

  @include tablet {
    margin-top: wds-rem(-80px);
  }
}
</style>
